<template>
  <div v-if="isHaveAccess" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm">
      <div class="text-left button-line pl-1">
        <b-btn variant="outline-primary" @click="showEditUserForm()">
          Nový uživatel
        </b-btn>
      </div>

      <div class="text-left filter-line">
        <span class="font-weight-bold pr-2 fs-17px pl-1">Filtry:</span>
        <div class="d-inline-block pl-1">
          <b-form-checkbox
              id="noBattery"
              v-model="showDeactivated"
              name="noBattery"
              class="mr-4 d-inline-block"
              @change="getUsers()"
          >
            vč. deaktivované
          </b-form-checkbox>
          <input
              type="text"
              v-model="filterString"
              class="d-inline-block w-200px"
              :class="[filterString ? 'input-filtered' : '']"
              placeholder="Jméno, Příjmení, Login, titul"
              @input="getUsers()"
          />
          <b-icon-x-circle
              @click="clearFilter"
              class="pl-2 pt-2 cursor-pointer"
              :class="[filterString ? 'filtered' : 'cursor-not-allowed']"
              font-scale="1.8"
              title="Čištění filtru"
          />
        </div>
      </div>

      <b-table
              sticky-header="calc(100vh - 14.5rem)"
              bordered
              responsive
              hover
              no-border-collapse
              class="stickyTable border-bottom"
              :items="users"
              :fields="headers"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
      >
        <template #cell(login)="{ item }">
          <div class="text-left">
            <b-icon-person-check v-if="item.enabled" variant="primary" title="Uživatel aktivován" font-scale="1.2"/>
            <b-icon-person-dash v-else variant="danger" title="Uživatel deaktivován" font-scale="1.2"/>
            <span class="pl-2" :class="getClassesByStatus(item)">{{ item.login }}</span>
          </div>
        </template>

        <template #cell(numberOZ)="{ item }">
            <div :class="getClassesByStatus(item)">{{ getOZandCategory(item) }}</div>
        </template>

        <template #cell(phone)="{ item }">
            <div :class="getClassesByStatus(item)">{{ item.phone }}</div>
        </template>

        <template #cell(joinedName)="{ item }">
            <div class="text-left" :class="getClassesByStatus(item)">{{ item.joinedName }}</div>
        </template>

        <template #cell(position)="{ item }">
            <div class="text-left" :class="getClassesByStatus(item)">{{ item.position }}</div>
        </template>

        <template #cell(email)="{ item }">
            <div class="text-left" :class="getClassesByStatus(item)">{{ item.email }}</div>
        </template>

        <template #cell(note)="{ item }">
            <div class="text-left" :class="getClassesByStatus(item)">{{ item.note }}</div>
        </template>

        <template #cell(pracovniUvazek)="{ item }">
            {{getPracovneUvazekDoba(item)}}
        </template>

        <template #cell(rizeni)="{ item }">
            <div class="text-center">
                <a v-if="$store.getters.ispPofilUzivateleModalAllowed" href="#"
                   title="Upraviť profil uživatele" @click.prevent="showEditUserForm(item)"
                   class="mr-4"
                >
                    <b-icon-pencil-fill/>
                </a>
                <span v-else class="mr-4 disabled-option">
                  <b-icon-pencil-fill title="Deaktivované"/>
                </span>

                <a v-if="$store.getters.isZmenitHesloUzivateleModalAllowed && item.enabled" href="#"
                   title="Upraviť heslo uživatele" @click.prevent="showEditPasswordForm(item)"
                   class="mr-4"
                >
                    <b-icon-shield-lock-fill variant="info"/>
                </a>
                <span v-else class="mr-4 disabled-option">
                  <b-icon-shield-lock-fill title="Deaktivované"/>
                </span>

                <a v-if="$store.getters.isUpravitPristupModalAllowed && item.enabled" href="#"
                   title="Upraviť přistup uživatele" @click.prevent="showEditEntitlementsForm(item)"
                   class="mr-4"
                >
                    <b-icon-gear-fill variant="secondary"/>
                </a>
                <span v-else class="mr-4 disabled-option">
                  <b-icon-gear-fill title="Deaktivované"/>
                </span>

                <a v-if="item.enabled" :href="'mailto:' + item.email" title="Poslat email uživateli">
                    <b-icon-envelope-fill class="user-enabled-email"/>
                </a>
                <b-icon-envelope-fill v-else class="user-disabled-email disabled-option" title="Deaktivované" />
            </div>
        </template>

        <template #head()="{ field }">
          <span v-html="field.label"></span>
        </template>
      </b-table>

      <div v-if="!users.length" class="pt-5">
        <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
      </div>

      <edit-user-modal
          v-if="showEditUserFormModal"
          :userId="selectedUser._id"
          :isAddUser="isAddUser"
          :show="showEditUserFormModal"
          @close-modal="closeModal"
          @form-saved="formSaved"
      />

      <edit-password-modal
          v-if="showEditPasswordFormModal"
          :userId="selectedUser._id"
          :userLogin="selectedUser.login"
          :show="showEditPasswordFormModal"
          @close-modal="closeModal"
      />

      <edit-user-entitlements-modal
          v-if="showEditEntitlementsModal"
          :show="showEditEntitlementsModal"
          :userId="selectedUser._id"
          :selectedUser="selectedUser"
          @close-modal="closeModal"
      />
    </b-overlay>
  </div>
</template>


<script>

import Axios from "axios";
import EditUserModal from "@/components/admin/Modals/EditUserModal.vue";
import EditPasswordModal from "@/components/admin/Modals/EditPasswordModal.vue";
import EditUserEntitlementsModal from "@/components/admin/Modals/EditUserEntitlementsModal.vue";
import commonMixin from "@/mixins/common.mixin.ts";
import {KATEGORIE_OZ_LIST, PRACOVNI_UVAZEK_LIST} from "@/constants/form.constants";

export default {
  components: {EditUserModal, EditPasswordModal, EditUserEntitlementsModal},
  mixins: [commonMixin],
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isHaveAccess() {
      return this.$store.getters.isAdmin || this.$store.getters.isUzivatelePageAllowed;
    },
  },
  data() {
    return {
      isLoading: false,
      users: [],
      showEditUserFormModal: false,
      showEditPasswordFormModal: false,
      showEditEntitlementsModal: false,
      selectedUser: null,
      isAddUser: false,
      filterString: '',
      showDeactivated: true,
      headers: [
        { label: 'Login', key: 'login', variant: 'color-1', sortable: true, stickyColumn: true, class: 'mw-160px' },
        { label: 'Řízení', key: 'rizeni', variant: 'color-1', class: 'mw-170px' },
        { label: 'Jméno, Příjmení<br><span class="font-weight-normal">(příp. titul)</span>', key: 'joinedName', variant: 'color-1', class: 'mw-200px', sortable: true },
        { label: 'Číslo uživatele<br><span class="font-weight-normal">(č.u.)</span>', key: 'cu', variant: 'color-1', sortable: true },
        { label: 'Pořadové číslo na seznamu kontaktů<br><span class="font-weight-normal">(s.č.)</span>', key: 'sc', variant: 'color-1', class: 'mw-145px', sortable: true },
        { label: 'Číslo a kategorie OZ', key: 'numberOZ', variant: 'color-1', sortable: true, class: 'mw-145px' },
        { label: 'Pracovní pozice', key: 'position', variant: 'color-1', class: 'mw-200px', sortable: true },
        { label: 'Pracovní úvazek<br><span class="font-weight-normal">(denní pracovní doba)</span>', key: 'pracovniUvazek', variant: 'color-1', class: 'mw-150px', sortable: true },
        { label: 'Telefonní číslo', key: 'phone', variant: 'color-1', class: 'mw-125px', sortable: true },
        { label: 'Email', key: 'email', variant: 'color-1', class: 'mw-145px', sortable: true },
        { label: 'Poznámka', key: 'note', variant: 'color-1', class: 'mw-220px', sortable: true },
      ],
      sortBy: 'joinedName',
      sortDesc: false,
      KATEGORIE_OZ_LIST,
    }
  },
  methods: {
    async getUsers() {
      try {
        const url = `/admin/getUsers`;
        const res = await Axios.post(url, {filter: this.filterString, showDeactivated: this.showDeactivated});
        const {data} = res;
        this.users = data.data.users;
      } catch (e) {
        console.log(e)
      }
    },
    async formSaved() {
      await this.getUsers();
    },
    showEditUserForm(user) {
      this.selectedUser = user || {};
      this.showEditUserFormModal = true;
      this.isAddUser = !user;
    },
    showEditPasswordForm(user) {
      this.selectedUser = user || {};
      this.showEditPasswordFormModal = true;
    },
    showEditEntitlementsForm(user) {
      this.selectedUser = user || {};
      this.showEditEntitlementsModal = true;
    },
    closeModal() {
      this.showEditUserFormModal = false
      this.showEditPasswordFormModal = false;
      this.showEditEntitlementsModal = false;
    },
    getClassesByStatus(item) {
      return item.enabled ? '' : 'user-disabled';
    },
    getOZandCategory(item) {
      const kategorieOZ = item.kategorieOZ ? `(${KATEGORIE_OZ_LIST.filter(cat => cat.value === item.kategorieOZ)[0].text})` : '';
      return `${item.numberOZ || ''} ${kategorieOZ}`
    },
    getPracovneUvazekDoba(item) {
      const denniPracovniDoba = item.denniPracovniDoba ? `(${item.denniPracovniDoba} hod.)` : '';
      const pracovniUvazek = this.getTextByKey(item.pracovniUvazek, PRACOVNI_UVAZEK_LIST);
      return `${pracovniUvazek} ${denniPracovniDoba}`;
    },
    async clearFilter() {
      this.filterString = '';
      await this.getUsers();
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getUsers();
    this.isLoading = false;
  },
};
</script>

<style scoped>

</style>