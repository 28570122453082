<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="edit-password-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Změnit heslo</h6>
      </div>
    </template>
    <b-overlay :show="isLoading" rounded="sm">
      <b-form @submit.stop.prevent="onSubmit">
        <div :class="getStyle1">
          <div :class="getStyle2">
            <label for="password" class="pr-1">Heslo</label>
            <label class="req">*</label>
          </div>
          <ValidationProvider rules="required|min:8|password" name="'Heslo'" v-slot="{ classes, errors }">
            <b-form-input
              id="password"
              type="password"
              size="sm"
              v-model="user.password"
              class="d-table-cell w-100"
              :title="errors[0]"
              :state="getState(classes)"
            />
            <span class="errMsg">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div :class="getStyle1">
          <div :class="getStyle2">
            <label for="passwordConfirm" class="pr-1">Potvrďte heslo</label>
            <label class="req">*</label>
          </div>
          <ValidationProvider rules="required|min:8|password" name="'Potvrďte heslo'" v-slot="{ classes, errors }">
            <b-form-input
              id="passwordConfirm"
              type="password"
              size="sm"
              v-model="user.passwordConfirm"
              class="d-table-cell w-100"
              :title="errors[0]"
              :state="getState(classes)"
            />
            <span class="errMsg">{{ errors[0] }}</span>
            <span v-if="isErrConfirm && !errors.length" class="errMsg">Neshoda hesla</span>
          </ValidationProvider>
        </div>

        <div class="d-flex justify-content-center w-100>">
          <b-button type="submit" class="mt-3 mb-1" variant="primary">Uložit změny</b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import {MESSAGES} from "@/constants/form.constants";

export default {
  computed: {
    MESSAGES() {
      return MESSAGES
    },
    getStyle1() {
      return 'text-left pt-3 w-500px d-table';
    },
    getStyle2() {
      return 'd-table-cell w-145px text-nowrap';
    },
    isHaveAccess() {
      return this.$store.getters.isAdmin || this.$store.getters.isZmenitHesloUzivateleModalAllowed;
    },
    isErrConfirm() {
      return this.user.password !== this.user.passwordConfirm;
    }
  },
  props: {
    userId: {type: String},
    userLogin: {type: String},
    show: {type: Boolean},
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      user: {
        password: null,
        passwordConfirm: null,
        _id: null,
      },
      errConfirm: false
    }
  },
  async mounted() {
    this.isLoading = true;
    this.user.login = this.userLogin;
    this.user._id = this.userId;
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    async onSubmit() {
      try {
        if (this.user.password !== this.user.passwordConfirm) {
          this.errConfirm = true;
          return;
        }

        this.isLoading = true;
        this.errConfirm = false;
        const url = `/admin/changeUserPassword`;
        const {data} = await axios.post(url, { data: this.user });
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('Change user password', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      } finally {
        this.isLoading = false;
      }
    },
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
  }
}
</script>

<style scoped>
.modalWindow {
  width: 550px;
}
label {
  padding-right: 10px;
  font-size: 15px;
}
.req {
  color: darkred;
  font-size: 17px
}
</style>
<script setup>
</script>