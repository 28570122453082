<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="entitlements-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Nastavit přistup pro "{{ getUserNamesAndTitles }}"</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <b-form @submit.stop.prevent="onSubmit">
        <div class="d-flex flex-wrap">
          <b-card v-for="page in entitlementPages" :key="page.value"
                  header-tag="header"
                  :border-variant="getPageBorderVariant(page.value)"
                  align="left"
                  class="card-width m-2"
                  :class="[isEmptyCard(page.value) ? 'visible-hidden' : '']"
          >
            <template #header>
              <b-form-checkbox-group
                  :id="page.value"
                  v-model="selectedEntitlPages"
                  :options="getPageData(page.value)"
                  :name="'page-' + page.value"
                  class="d-inline-block fs-15px"
                  switches
                  @change="unSelectPage(page.value)"
              >
              </b-form-checkbox-group>
            </template>

            <b-form-checkbox-group
                v-model="selectedEntitlModals"
                :options="entitlementModals[page.value]"
                :name="'accessModals-' + page.value"
                stacked
                class="text-left pl-0 fs-13px"
                size="sm"
                :disabled="!isPageEnabled(page.value)"
            />
          </b-card>
        </div>

        <div class="d-flex justify-content-center w-100>">
          <b-button type="submit" class="mt-4 mb-2" variant="primary">Uložit změny</b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['isUpravitPristupModalAllowed', 'isAdmin']),
    isHaveAccess() {
      return this.isAdmin || this.isUpravitPristupModalAllowed;
    },
    getUserNamesAndTitles() {
      const {titleBeforeName, titleAfterName, name, lastName} = this.selectedUser;
      const joinedName = [titleBeforeName, name, lastName].filter(n => n).join(' ');
      return titleAfterName ? `${joinedName}, ${titleAfterName}` : joinedName;
    },
  },
  props: {
    userId: {type: String},
    selectedUser: {type: Object},
    show: {type: Boolean},
  },
  data() {
    return {
      entitlementsList: {},
      selectedEntitlPages: [],
      selectedEntitlModals: [],
      entitlementPages: [],
      entitlementModals: [],
      isLoading: false,
      showLocal: false,
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getEntitlementsList();
    await this.getUserEntitlements();
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const url = `/admin/saveUserEntitlements`;
        const {data} = await axios.post(url,
            {
              id: this.userId,
              userLogin: this.selectedUser.login,
              entitlementPages: this.selectedEntitlPages,
              entitlementModals: this.selectedEntitlModals
            }
        );
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('Save settings for user access', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      } finally {
        this.isLoading = false;
      }
    },
    async getEntitlementsList() {
      const url = `/admin/getUserEntitlementsList`;
      const {data} = await axios.get(url);
      const entitlementsList = data.data?.userEntitlementsList || {entitlementPages: [], entitlementModals: []};
      this.entitlementPages = entitlementsList.entitlementPages;
      this.entitlementModals = this.getEntitlementModals(entitlementsList.entitlementModals);
    },
    async getUserEntitlements() {
      const url = `/admin/getUserEntitlements`;
      const {data} = await axios.get(url, {params: {id: this.userId}});
      const userEntitlements = data.data?.userEntitlements || {entitlementPages: [], entitlementModals: []};
      this.selectedEntitlPages = userEntitlements.entitlementPages;
      this.selectedEntitlModals = userEntitlements.entitlementModals;
    },
    getEntitlementModals(entitlementModals) {
      return this.entitlementPages.reduce((acc, page) => {
        acc[page.value] = entitlementModals.filter(modal => modal.pageCode === page.value);
        return acc;
      }, {});
    },
    getPageBorderVariant(pageCode) {
      return this.selectedEntitlPages.includes(pageCode) ? 'success' : 'danger'
    },
    isPageEnabled(pageCode) {
      return this.selectedEntitlPages.includes(pageCode);
    },
    getPageData(pageCode) {
      return this.entitlementPages.filter(p => p.value === pageCode);
    },
    isEmptyCard(pageCode) {
      return pageCode.includes('empty');
    },
    unSelectPage(pageCode) {
      if (this.selectedEntitlPages.includes(pageCode)) {
        return;
      }

      const modalsForPage = this.entitlementModals[pageCode].map(m => m.value);
      this.selectedEntitlModals = this.selectedEntitlModals.filter(modalCode => !modalsForPage.includes(modalCode));
    },
  },
}
</script>

<style scoped>
  .card-width {
    width: calc(25% - 16px);
  }
  .visible-hidden {
    visibility: hidden;
  }
</style>