<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="edit-user-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 v-if="isAddUser" class="text-nowrap w-100">Nový uživatel</h6>
        <h6 v-else class="text-nowrap w-100">Profil uživatele</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <div class="d-flex w-100">
            <div :class="getStyle2">
              <label for="login" class="pr-1 bold">Login</label>
              <label class="req">*</label>
            </div>
            <ValidationProvider rules="required|min:3" name="'Login'" v-slot="{ classes, errors }" class="w-calc-right-50">
              <b-form-input
                  id="login"
                  type="text"
                  size="sm"
                  v-model.trim="user.login"
                  :title="errors[0]"
                  :state="getState(classes)"
                  class="w-100"
              />
            </ValidationProvider>

            <div v-if="isAddUser" class="d-flex w-50">
              <div class="d-flex pl-4">
                <label for="password" class="pr-1 pt-1">Heslo</label>
                <label class="req">*</label>
              </div>
              <div class="d-flex pl-1 w-100">
                <ValidationProvider rules="required|min:8|password" name="'Heslo'" v-slot="{ classes, errors }" class="w-100">
                  <b-form-input
                      id="password"
                      type="text"
                      size="sm"
                      v-model.trim="user.password"
                      class="pr-1"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                  <span class="errMsg">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="isAddUser" class="pl-5">
              <a
                  v-if="user.login && user.password && user.email"
                  :href="getMailTo()"
                  title="Poslat email"
              >
                <b-icon-envelope-fill class="user-enabled-email" font-scale="1.3"/>
              </a>
              <b-icon-envelope-fill v-else class="user-disabled-email" font-scale="1.3"/>
            </div>
          </div>

          <div class="d-flex pt-3 w-100">
            <div :class="getStyle2">
              <label for="titleBeforeName" class="text-nowrap">
                Tituly před jménem
              </label>
            </div>
            <div class="d-flex w-calc-right justify-content-between">
              <b-form-input
                  id="titleBeforeName"
                  type="text"
                  size="sm"
                  v-model.trim="user.titleBeforeName"
                  class="d-inline-block w-50px"
              />

              <div class="text-nowrap">
                <label for="name" class="pl-3 pr-1">Jméno</label>
                <label class="req">*</label>
              </div>
              <ValidationProvider rules="required|min:3" name="'Jméno'" v-slot="{ classes, errors }">
                <b-form-input
                    id="name"
                    type="text"
                    size="sm"
                    v-model.trim="user.name"
                    class="d-inline-block w-110px text-capitalize"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>

              <div class="text-nowrap">
                <label for="lastName" class="pl-3">Příjmení</label>
                <label class="req">*</label>
              </div>
              <ValidationProvider rules="required|min:3" name="'Příjmení'" v-slot="{ classes, errors }">
                <b-form-input
                    id="lastName"
                    type="text"
                    size="sm"
                    v-model.trim="user.lastName"
                    class="d-table-cell w-100 text-capitalize"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
              <div class="text-nowrap">
                <label for="titleAfterName" class="pl-3 pt-2px">Tituly za jménem</label>
              </div>
              <b-form-input
                  id="titleAfterName"
                  type="text"
                  size="sm"
                  v-model.trim="user.titleAfterName"
                  class="d-inline-block w-50px"
              />
            </div>
          </div>

          <div class="d-flex pt-3 w-100">
            <div :class="getStyle2">
              <label for="phone" class="pr-1">Telefonní číslo</label>
              <label class="req">*</label>
            </div>
            <div class="d-flex w-calc-right">
              <ValidationProvider rules="required|min:11" name="'Telefonní číslo'" v-slot="{ classes, errors }">
                <b-form-input
                    id="phone"
                    type="tel"
                    size="sm"
                    v-model.trim="user.phone"
                    class="d-inline-block w-200px"
                    v-mask="'### ### ###'"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
              <div class="d-flex">
                <div class="text-nowrap">
                  <label for="email" class="pl-4 pr-1">Emailová adresa</label>
                  <label class="req">*</label>
                </div>
                <ValidationProvider rules="required|email" name="'Emailová adresa'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="email"
                      type="email"
                      size="sm"
                      v-model.trim="user.email"
                      class="d-table-cell w-200px"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>

          <hr class="mt-4 mb-4">

          <div>
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="user.userTypes"
                :options="USER_TYPES_LIST"
                name="checkbox-options"
                @change="clickUserTypeCheckbox"
                class="checkbox-group-user-type"
            ></b-form-checkbox-group>
          </div>

          <div v-if="isOzUser()" class="d-flex w-100 pt-3 pr-4">
            <div class="d-flex pr-3">
              <label for="numberOZ" class="pr-1 pt-1 text-nowrap">číslo OZ</label>
              <div>
                <ValidationProvider rules="required" name="'číslo OZ'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="numberOZ"
                      type="text"
                      size="sm"
                      v-model.trim="user.tempNumberOZ"
                      @input="handleInput"
                      class="ml-1 form-control form-control-sm w-75px"
                      :title="errors[0]"
                      :state="getState(classes)"
                      @change="validateUniqueNumberOz()"
                  />
                </ValidationProvider>
                <div v-if="isOzNumberExist" class="text-center">
                  <span class="errMsg">Číslo OZ již existuje</span>
                </div>
              </div>
            </div>

            <div class="d-flex pr-3">
              <label for="kategorieOZ" class="pl-4 pr-1 pt-1 text-nowrap">kategorie OZ</label>
              <b-form-select
                  id="kategorieOZ"
                  name="kategorieOZ"
                  v-model="user.kategorieOZ"
                  :options="KATEGORIE_OZ_LIST"
                  class="ml-1 form-select form-select-sm w-130px"
                  size="sm"
                  :disabled="!user.tempNumberOZ"
              />
            </div>

            <div class="d-flex">
              <label for="vidiZakazky" class="pl-4 pr-1 pt-1 text-nowrap">vidí zakázky</label>
              <b-form-select
                  id="vidiZakazky"
                  name="vidiZakazky"
                  v-model="user.accessByOz"
                  :options="VIDI_ZAKAZKY"
                  class="ml-1 form-select form-select-sm"
                  size="sm"
                  :disabled="!user.tempNumberOZ"
                  @change="changeVidiZakazky"
              />
            </div>
          </div>

          <div v-if="isAccessForGroup()" class="d-flex pt-3 w-100">
            <div class="w-calc-right d-flex pl-4 w-100 pr-4">
              <span class="pr-2 pt-2 w-110px">aktivováné</span>
              <multiselect
                  v-model="ozUserGroupMembers"
                  placeholder="Vybrat skupinu lidí"
                  label="name"
                  track-by="id"
                  :options="lists.obchodniZastupce"
                  :multiple="true"
                  :hideSelected="true"
                  selectLabel="Pro výběr stiskněte enter"
              />
            </div>
          </div>
          <div v-if="isAccessForGroup()" class="d-flex pt-3 w-100">
            <div class="w-calc-right d-flex pl-4 w-100 pr-4">
              <span class="pr-2 pt-2 w-110px">deaktivováné</span>
              <multiselect
                  v-model="ozUserGroupMembersDeactivated"
                  placeholder="Vybrat skupinu lidí"
                  label="name"
                  track-by="id"
                  :options="lists.obchodniZastupceDisabled"
                  :multiple="true"
                  :hideSelected="true"
                  selectLabel="Pro výběr stiskněte enter"
                  class="obchodni-zastupce-disabled"
              />
            </div>
          </div>

          <hr class="mt-4 mb-3">

          <div class="d-flex w-100 justify-content-between">
            <b-form-group class="label-1" id="divize-group">
              <label for="divize">Divize</label>
              <ValidationProvider rules="max:60" name="'Divize'" v-slot="{ classes, errors }">
                <b-form-input
                    id="divize"
                    type="text"
                    size="sm"
                    v-model="user.divize"
                    class="pl-2 w-160px"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="position-group">
              <label for="position" class="text-nowrap">Pracovní pozice</label>
              <ValidationProvider rules="max:60" name="'Pracovní pozice'" v-slot="{ classes, errors }">
                <b-form-input
                    id="position"
                    type="text"
                    size="sm"
                    v-model.trim="user.position"
                    class="pl-2 w-350px"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="sc-group">
              <label for="sc" class="text-nowrap">s.č.</label>
              <ValidationProvider rules="integer|between:0,999" name="'s.č.'" v-slot="{ classes, errors }">
                <b-form-input
                    id="sc"
                    type="text"
                    size="sm"
                    v-model="user.sc"
                    class="w-75px pl-2"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="note-group">
              <label for="note" class="text-nowrap">Poznámka</label>
              <ValidationProvider rules="max:60" name="'Poznámka'" v-slot="{ classes, errors }">
                <b-form-input
                    id="note"
                    type="text"
                    size="sm"
                    v-model="user.note"
                    class="pl-2 w-320px"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
            </b-form-group>
          </div>

          <div class="d-flex w-100 pt-3 justify-content-between">
            <b-form-group class="label-1" id="cu-group">
              <label for="cu" class="text-nowrap">č.u.</label>
              <ValidationProvider rules="max:4" name="'č.u.'" v-slot="{ classes, errors }">
                <b-form-input
                    id="cu"
                    type="text"
                    size="sm"
                    v-model="user.cu"
                    class="w-80px"
                    :title="errors[0]"
                    :state="getState(classes)"
                    @change="validateUniqueCu()"
                />
              </ValidationProvider>
              <div v-if="isCuExist" class="text-center">
                <span class="errMsg">č.u. již existuje</span>
              </div>
            </b-form-group>

            <b-form-group class="label-1 pl-4 w-150px" id="pracovniUvazek-group">
              <label for="pracovniUvazek" class="text-nowrap">Pracovní úvazek</label>
              <b-form-select
                  id="pracovniUvazek"
                  name="pracovniUvazek"
                  v-model="user.pracovniUvazek"
                  :options="PRACOVNI_UVAZEK_LIST"
                  class="form-select form-select-sm"
                  size="sm"
              />
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="denniPracovniDoba-group">
              <label for="denniPracovniDoba" class="text-nowrap">Denní pracovní doba</label>
              <ValidationProvider rules="double|between:0,20" name="'Denní pracovní doba'" v-slot="{ classes, errors }">
                <b-form-input
                    id="denniPracovniDoba"
                    type="text"
                    size="sm"
                    v-model="user.denniPracovniDoba"
                    class="w-160px"
                    :title="errors[0]"
                    :state="getState(classes)"
                />
              </ValidationProvider>
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="denNastupu-group">
              <label for="denNastupu">Den nastupu</label>
              <b-form-datepicker
                  id="den-nastupu-datepicker"
                  v-model="user.denNastupu"
                  :start-weekday="1"
                  size="sm"
                  locale="cs"
                  class="w-260px"
              />
            </b-form-group>

            <b-form-group class="label-1 pl-4" id="denUkonceni-group">
              <label for="denUkonceni">Den ukončení</label>
              <b-form-datepicker
                  id="den-ukonceni-datepicker"
                  v-model="user.denUkonceni"
                  :start-weekday="1"
                  size="sm"
                  locale="cs"
                  class="w-260px"
              />
            </b-form-group>
          </div>

          <div class="d-flex pt-3 w-100">
            <label for="userStatus" class="bold">Stav</label>
            <b-form-checkbox
                id="userStatus"
                v-model="user.enabled"
                name="userStatus"
                class="ml-1 pr-4"
            >
              Uživatel aktivován
            </b-form-checkbox>

            <b-form-checkbox
                id="naSeznamuKontaktu"
                v-model="user.naSeznamuKontaktu"
                name="naSeznamuKontaktu"
                class="ml-4"
            >
              na seznamu kontaktů
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-center w-100>">
            <b-button type="submit" class="mt-3 mb-1" variant="primary">Uložit změny</b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import {
  BCC_ASISTENT_EMAIL,
  MESSAGES,
  USER_TYPES,
  USER_TYPES_LIST,
  KATEGORIE_OZ,
  KATEGORIE_OZ_LIST,
  OPTIONS_ACCESS_BY_OZ,
  VIDI_ZAKAZKY,
  PRACOVNI_UVAZEK_LIST,
  PRACOVNI_UVAZEK_TYPES,
} from "@/constants/form.constants";
import commonMixin from "@/mixins/common.mixin.ts";
import Multiselect from 'vue-multiselect'
import _ from "lodash";
import mailtoLink from 'mailto-link';

export default {
  components: { Multiselect },
  computed: {
    MESSAGES() {
      return MESSAGES
    },
    getStyle2() {
      return 'w-150px mw-150px text-nowrap';
    },
  },
  mixins: [commonMixin],
  props: {
    userId: {type: String},
    isAddUser: {type: Boolean, default: false},
    show: {type: Boolean},
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      ozUserGroupMembers: [],
      ozUserGroupMembersDeactivated: [],
      adminEmails: [],
      user: {
        login: null,
        name: null,
        lastName: null,
        titleAfterName: null,
        titleBeforeName: null,
        enabled: null,
        password: null,
        position: null,
        phone: null,
        email: null,
        note: null,
        divize: null,
        sc: null,
        numberOZ: null,
        tempNumberOZ: null,
        kategorieOZ: null,
        ozUserGroupIds: [],
        ozUserGroupDeactivatedIds: [],
        accessByOz: null,
        userTypes: [],
        cu: null,
        pracovniUvazek: null,
        denniPracovniDoba: null,
        denNastupu: null,
        denUkonceni: null,
        naSeznamuKontaktu: null,
      },
      lists: {
        obchodniZastupce: [],
        obchodniZastupceDisabled: [],
        obchodniZastupceFull: [],
        obchodniZastupceDisabledFull: [],
        obchodniZastupceObjById: [],
        obchodniZastupceObjByIdDisabled: [],
      },
      USER_TYPES,
      USER_TYPES_LIST,
      KATEGORIE_OZ_LIST,
      OPTIONS_ACCESS_BY_OZ,
      VIDI_ZAKAZKY,
      PRACOVNI_UVAZEK_LIST,
      PRACOVNI_UVAZEK_TYPES,
      isOzNumberExist: false,
      isCuExist: false,
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getObchodniZastupceList();
    await this.getAdminEmails();
    await this.getInitData();
    if (this.isAddUser) {
      this.user.pracovniUvazek = PRACOVNI_UVAZEK_TYPES.DPP;
    }
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    isOzUser() {
      return this.user?.userTypes?.includes(USER_TYPES.OZ);
    },
    async getInitData() {
      try {
        if (!this.userId) {
          return;
        }

        const getMembers = (ids = [], list) => {
          const group = [];
          ids.forEach(id => {
            const ozUserName = _.get(this, `lists.${list}.${id}`, '');
            if (ozUserName) {
              group.push({
                name: ozUserName,
                id: id,
              })
            }
          });
          return group;
        }

        const urlUser = `/admin/getUser`;
        const { data: dataUser } = await axios.get(urlUser, { params: { id: this.userId } });
        this.user = {...this.user, ...dataUser.data.user};
        this.user.tempNumberOZ = this.user.numberOZ;

        this.ozUserGroupMembers = getMembers(this.user?.ozUserGroupIds, 'obchodniZastupceObjById');
        this.ozUserGroupMembersDeactivated = getMembers(this.user?.ozUserGroupDeactivatedIds, 'obchodniZastupceObjByIdDisabled');

        if (this.user.numberOZ) {
          this.user.accessByOz = this.user.accessByOz || OPTIONS_ACCESS_BY_OZ.ONLY_OWN;
          if (this.isAccessForGroup()) {
            this.ozUserGroupMembers.unshift({
              name: this.joinName(this.user),
              id: this.userId,
              $isDisabled: true
            });
            this.ozUserGroupMembersDeactivated.unshift({
              name: this.joinName(this.user),
              id: this.userId,
              $isDisabled: true
            });
          }
        }
      } catch (e) {
        console.error('EditUserModal "getInitData"', e);
        throw new Error()
      }
    },
    getIdsForOzUserGroupMembers() {
      return this.ozUserGroupMembers.map(member => member.id).filter(id => id !== this.userId);
    },
    getIdsForOzUserGroupMembersDeactivated() {
      return this.ozUserGroupMembersDeactivated.map(member => member.id).filter(id => id !== this.userId);
    },
    isAccessForGroup() {
      return this.user.accessByOz === OPTIONS_ACCESS_BY_OZ.FOR_SELECTED_GROUP;
    },
    async onSubmit() {
      try {
        if (!this.isAccessForGroup()) {
          this.ozUserGroupMembers = [];
          this.ozUserGroupMembersDeactivated = [];
        }
        if (!this.user.numberOZ) {
          this.clearOzData();
        }

        await this.validateUniqueNumberOz();
        await this.validateUniqueCu();
        if (this.isOzNumberExist) {
          this.$snotify['error']('Číslo OZ již existuje');
          return;
        }
        if (this.isCuExist) {
          this.$snotify['error']('č.u. již existuje');
          return;
        }

        this.isLoading = true;
        const url = `/admin/${this.isAddUser ? 'createUser' : 'updateUser'}`;
        this.user.ozUserGroupIds = this.getIdsForOzUserGroupMembers();
        this.user.ozUserGroupDeactivatedIds = this.getIdsForOzUserGroupMembersDeactivated();
        const {data} = await axios.post(url, { data: this.user });
        this.$snotify[data.status](data.data.message);

        if (this.user._id === this.$store.getters.userInfo._id) {
          await this.$store.dispatch('getUserInfo');
        }
        this.isLoading = false;
        if (data.status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('EditUser', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getObchodniZastupceList() {
      const url = `/user/getObchodniZastupceList`;
      const {data} = await axios.get(url, {});
      this.lists.obchodniZastupceFull = data?.data?.activeOzUsersInfo || [];
      this.lists.obchodniZastupceDisabledFull = data?.data?.deactivatedOzUsersInfo || [];

      this.lists.obchodniZastupceFull.forEach(user => {
        if (user.id !== this.userId) {
          this.lists.obchodniZastupce.push({
            name: user.fullName,
            id: user.id,
          })
        }
      });
      this.lists.obchodniZastupceFull.forEach(user => {
        this.lists.obchodniZastupceObjById[user.id] = user.fullName;
      });

      this.lists.obchodniZastupceDisabledFull.forEach(user => {
        if (user.id !== this.userId) {
          this.lists.obchodniZastupceDisabled.push({
            name: user.fullName,
            id: user.id,
          })
        }
      });
      this.lists.obchodniZastupceDisabledFull.forEach(user => {
        this.lists.obchodniZastupceObjByIdDisabled[user.id] = user.fullName;
      });
    },

    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    handleInput() {
      if (!this.user.tempNumberOZ) {
        this.clearOzData();
        return;
      }

      const values = this.handleInputNumbers(this.user.numberOZ, this.user.tempNumberOZ);
      this.user.numberOZ = values.value;
      this.user.tempNumberOZ = values.tempValue;
      this.user.accessByOz = this.user.accessByOz || OPTIONS_ACCESS_BY_OZ.ONLY_OWN;
      this.user.kategorieOZ = this.user.kategorieOZ || KATEGORIE_OZ.JUNIOR;
    },
    clickUserTypeCheckbox(checked) {
      if (!checked.includes(USER_TYPES.OZ)) {
        this.clearOzData();
      }
    },
    changeVidiZakazky() {
      const {ONLY_OWN, ALL, FOR_SELECTED_GROUP} = OPTIONS_ACCESS_BY_OZ;
      switch (this.user.accessByOz) {
        case ONLY_OWN:
          this.ozUserGroupMembers = [];
          break;
        case ALL:
          this.ozUserGroupMembers = [];
          break;
        case FOR_SELECTED_GROUP:
          this.ozUserGroupMembers.unshift({
            name: this.joinName(this.user),
            id: this.userId,
            $isDisabled: true
          });
          break;
      }
    },
    clearOzData() {
      this.user.numberOZ = null;
      this.user.tempNumberOZ = null;
      this.ozUserGroupMembers = [];
      this.ozUserGroupMembersDeactivated = [];
      this.user.accessByOz = null;
      this.user.kategorieOZ = null;
    },
    isHaveAccess() {
      return this.$store.getters.isAdmin || this.$store.getters.ispPofilUzivateleModalAllowed;
    },
    getMailTo() {
      const cc = this.adminEmails.join(', ');
      return  mailtoLink({
        to: this.user.email,
        cc: cc,
        bcc: BCC_ASISTENT_EMAIL,
        subject: 'Přihlášení do systému CRM Trienergo',
        body: `
  Dobrý den,
  posílám přihlašovací údaje do systému CRM Trienergo

  https://fve-creator.cz/

  Login: ${this.user.login}
  Heslo: ${this.user.password}

`
      });
    },
    async getAdminEmails() {
      const url = `/admin/getAdminEmails`;
      const {data} = await axios.get(url, {});
      const currUserEmail = this.$store.getters.userInfo.email;
      this.adminEmails = data?.data?.emails || [];
      if (!this.adminEmails.includes(currUserEmail)) {
        this.adminEmails.push(currUserEmail);
      }
    },
    async validateUniqueNumberOz() {
      try {
        if (!this.user.tempNumberOZ) {
          return;
        }
        const url = `/user/validateUserFieldsByUnique`;
        const {data: {data}} = await axios.post(url, {id: this.userId, fieldName: 'numberOZ', fieldValue: this.user.tempNumberOZ});
        this.isOzNumberExist = data.isExist;
      } catch (e) {
        console.error('EditUserModal "validateUniqueNumberOz"', e);
      }
    },
    async validateUniqueCu() {
      try {
        if (!this.user.cu) {
          return;
        }
        const url = `/user/validateUserFieldsByUnique`;
        const {data: {data}} = await axios.post(url, {id: this.userId, fieldName: 'cu', fieldValue: this.user.cu});
        this.isCuExist = data.isExist;
      } catch (e) {
        console.error('EditUserModal "validateUniqueCu"', e);
      }
    },
  },
}
</script>

<style scoped>
label {
  padding-right: 10px;
  font-size: 15px;
}
.req {
  color: darkred;
  font-size: 17px
}
.w-calc-right {
  width: calc(100% - 150px);
}
.w-calc-right-50 {
  width: calc(50% - 100px);
}
.checkbox-group-user-type {
  display: flex;
  justify-content: space-between;
}
.label-1 {
  font-weight: normal;
}

</style>